import { faFaceFrown } from '@fortawesome/free-regular-svg-icons';
import {
  fa4,
  faCab,
  faCalculator,
  faFaceGrinTongueWink,
  faFastBackward,
  faHand,
  faHistory,
  faImage,
  faLaptopMedical,
  faMailBulk,
  faMailForward,
  faMailReplyAll,
  faMarsAndVenusBurst,
  faMaskFace,
  faPlane,
  faReceipt,
  faServer,
  faSignLanguage,
  faTachometer,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 20px 20px 0 20px;
  gap: 20px;
`;

const CustomLink = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  font-weight: bold;
  color: #05275d;
  font-family: Work Sans;
  gap: 8px;
  padding: 32px;
  border-radius: 16px;
  background: rgba(79, 167, 180, 0.2);
  transition: all 0.3s;

  &:hover {
    transform: scale(0.8);
  }

  @media (max-width: 600px) {
    padding: 10px;
    font-size: 16px;
    border-radius: 10px;
  }
`;

const IconWrapper = styled.div`
  font-size: 32px;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 600px) {
    font-size: 16px;
  }
`;

const AdminElements = [
  {
    name: 'Ip Stats',
    link: '/admin/ip-stats',
    icon: faServer,
  },
  {
    name: 'Usage Stats',
    link: '/admin/usage-stats',
    icon: faSignLanguage,
  },
  {
    name: 'Image mode Stats',
    link: '/admin/image-mode-stats',
    icon: faImage,
  },
  {
    name: 'Quality indicator',
    link: '/admin/quality-indicator',
    icon: faTachometer,
  },
  {
    name: 'Daily history',
    link: '/admin/daily-history',
    icon: faHistory,
  },
  {
    name: 'Hands',
    link: '/admin/hands',
    icon: faHand,
  },
  {
    name: 'Opinions',
    link: '/admin/opinions',
    icon: faCab,
  },
  {
    name: 'Wysłane maile',
    link: '/admin/mail',
    icon: faMailBulk,
  },
  {
    name: 'Panel',
    link: '/admin/panel',
    icon: faPlane,
  },
  {
    name: 'FB',
    link: '/admin/meta-report',
    icon: faLaptopMedical,
  },
  {
    name: 'UTM',
    link: '/admin/utm-report',
    icon: faLaptopMedical,
  },
  {
    name: 'Mail Report',
    link: '/admin/mail-report',
    icon: faMailBulk,
  },
  {
    name: 'Chat Test Report',
    link: '/admin/chat-test-report',
    icon: faMailBulk,
  },
  {
    name: 'Refundation report',
    link: '/admin/refundation-report',
    icon: faReceipt,
  },
  {
    name: 'Financial report',
    link: '/admin/financial-report',
    icon: faCalculator,
  },
  {
    name: 'Renew report',
    link: '/admin/renew-report',
    icon: faMaskFace,
  },
  {
    name: 'Payment report',
    link: '/admin/payment-report',
    icon: faMaskFace,
  },
  {
    name: 'Quiz report',
    link: '/admin/quiz-report',
    icon: faMaskFace,
  },
];
const Report = () => {
  return (
    <Wrapper>
      {AdminElements.map((element) => (
        <CustomLink to={element.link}>
          <IconWrapper>
            <FontAwesomeIcon icon={element.icon} />
          </IconWrapper>
          {element.name}
        </CustomLink>
      ))}
    </Wrapper>
  );
};

export default Report;
