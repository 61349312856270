import React, { useEffect, useRef, useState } from 'react';
import { styled } from 'styled-components';
import { Color } from '@/common/colors/colors';
import moment from 'moment';
import Input from '@/common/components/custom/Input';
import SelectInput from '@/common/components/custom/SelectInput';
import CSV from '@/common/components/CSV';
import { useMutation } from 'react-query';
import { getCountries } from '@/api/query/country';
import Loading from '@/common/components/Loading';

const SelectBarContainer = styled.form`
  border-radius: 10px;
  background-color: ${Color.White};
  padding: 10px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  border: 1px dashed grey;
`;

const SearchButton = styled.button`
  padding: 10px 20px;
  background-color: ${Color.DarkBlue};
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
  margin-left: 20px;

  &:hover {
    background-color: ${Color.Grey};
  }
`;

const OPTIONS_SOURCE = [
  {
    label: 'fb',
    value: 'fb',
  },
  {
    label: 'google',
    value: 'google',
  },
  {
    label: 'ms',
    value: 'ms',
  },
  {
    label: 'all',
    value: 'all',
  },
];

const OPTIONS_DEVICE = [
  {
    label: 'mobile',
    value: 'mobile',
  },
  {
    label: 'desktop',
    value: 'desktop',
  },
  {
    label: 'all',
    value: 'all',
  },
];

const OPTIONS_LANG_TO_LEARN = [
  {
    label: 'en',
    value: 'en',
  },
  {
    label: 'de',
    value: 'de',
  },
  {
    label: 'it',
    value: 'it',
  },
  {
    label: 'fr',
    value: 'fr',
  },
  {
    label: 'es',
    value: 'es',
  },
  {
    label: 'all',
    value: 'all',
  },
];

const SearchBar = ({ handleSearch, csvData, headers }) => {
  const fromRef = useRef();
  const toRef = useRef();
  const [countriesOptions, setCountriesOptions] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState();
  const [selectedDevice, setSelectedDevice] = useState(OPTIONS_DEVICE[2]);
  const [selectedLangToLearn, setSelectedLangToLearn] = useState(
    OPTIONS_LANG_TO_LEARN[5],
  );
  const [selectedSource, setSelectedSource] = useState(OPTIONS_SOURCE[3]);

  const handleLoadCountries = useMutation({
    mutationFn: () => getCountries(),
    onSuccess: ({ data }) => {
      const mapedCountries = data.map((d) => ({
        label: d.name,
        value: d?.language?.short,
      }));

      setCountriesOptions(() => mapedCountries);
    },
  });

  const handleSearchCheck = (e) => {
    e?.preventDefault();
    const payload = {};

    payload.from = fromRef.current.value;
    payload.to = toRef.current.value;
    payload.country = selectedCountry.value;
    payload.ltl = selectedLangToLearn.value;
    payload.device = selectedDevice.value;
    payload.source = selectedSource.value;

    console.log(payload);
    handleSearch(payload);
  };

  useEffect(() => {
    handleLoadCountries.mutate();
  }, []);

  return (
    <>
      {handleLoadCountries.isLoading && <Loading />}
      <SelectBarContainer onSubmit={handleSearchCheck}>
        <div>
          <SelectInput
            name={'Country'}
            width={140}
            options={countriesOptions}
            selectWidth={160}
            selected={selectedCountry}
            setSelected={setSelectedCountry}
          />
          <SelectInput
            name={'Lang to Learn'}
            width={140}
            options={OPTIONS_LANG_TO_LEARN}
            selectWidth={160}
            selected={selectedLangToLearn}
            setSelected={setSelectedLangToLearn}
          />
        </div>
        <div>
          <SelectInput
            name={'Device'}
            width={80}
            options={OPTIONS_DEVICE}
            selectWidth={160}
            selected={selectedDevice}
            setSelected={setSelectedDevice}
          />
          <SelectInput
            name={'Source'}
            width={80}
            options={OPTIONS_SOURCE}
            selectWidth={160}
            selected={selectedSource}
            setSelected={setSelectedSource}
          />
        </div>
        <div>
          <Input
            type="date"
            inputRef={fromRef}
            name="Event date From"
            inputWidth={120}
            width={160}
            required={true}
            value={moment().format('YYYY-MM-DD')}
          />
          <Input
            type="date"
            inputRef={toRef}
            name="Event date to"
            inputWidth={120}
            width={160}
            required={true}
            value={moment().format('YYYY-MM-DD')}
          />
        </div>

        <SearchButton type="submit">Search</SearchButton>
      </SelectBarContainer>
    </>
  );
};

export default SearchBar;
