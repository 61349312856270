import Login from '@/pages/login/Login';
import Home from '@/pages/customer/home/Home';
import ProductLayout from '../components/layout/ProductLayout';
import Lesson from '@/pages/customer/lessons/Lesson';
import Lessons from '@/pages/customer/lessons/Lessons';
import AdminHome from '@/pages/admin/home/AdminHome';
import AdminLesson from '@/pages/admin/lesson/AdminLesson';
import AdminUser from '@/pages/admin/user/AdminUser';
import Profile from '@/pages/customer/profile/Profile';
import AdminCategory from '@/pages/admin/category/AdminCategory';
import AdminWordLessonType from '@/pages/admin/word-lesson-type/AdminWordLessonType';
import AdminWordLessonTopType from '@/pages/admin/word-lesson-top-type/AdminWordLessonTopType';
import WordLessonType from '@/pages/customer/word-lesson-type/WordLessonType';
import WordLessonTypeList from '@/pages/customer/word-lesson-type/WordLessonTypeList';
import AdminWordLessonConfig from '@/pages/admin/word-lesson-type-config/AdminWordLessonConfig';
import RestartPassword from '@/pages/restartPassword/RestartPassword';
import AdminMail from '@/pages/admin/mail/AdminMail';
import AdminOrder from '@/pages/admin/order/AdminOrder';
import AdminPanel from '@/pages/admin/panel/AdminPanel';
import Success from '@/pages/common/Success';
import AdminHands from '@/pages/admin/hands/AdminHands';
import AdminFlashcard from '@/pages/admin/flashcard/AdminFlashcard';
import AdminDailyHistory from '@/pages/admin/daily-history/AdminDailyHistory';
import Learn from '@/pages/customer/learn/Learn';
import FotoMode from '@/pages/customer/fotoMode/FotoMode';
import AdminImageMode from '@/pages/admin/image-mode/AdminImageMode';
import AdminIpStats from '@/pages/admin/report-ip-stats/AdminIpStats';
import AdminUsage from '@/pages/admin/usage-report/AdminUsage';
import AdminFotoModeStats from '@/pages/admin/foto-mode-stats/AdminFotoModeStats';
import NewFlashcardList from '@/pages/customer/new-flashcard/NewFlashcardList';
import AdminFlashcardCategory from '@/pages/admin/flashcard-category/AdminFlashcardCategory';
import NewFlashcardCategory from '@/pages/customer/new-flashcard/NewFlashcardCategory';
import NewFlashcard from '@/pages/customer/new-flashcard/NewFlashcard';
import AdminDailyConversation from '@/pages/admin/daily-conversation/AdminDailyConversation';
import AdminAiPerson from '@/pages/admin/ai-person/AdminAiPerson';
import ProfileLearning from '@/pages/customer/profile/subpages/ProfileLearning';
import ProfileTutor from '@/pages/customer/profile/subpages/ProfileTutor';
import ProfileHobbies from '@/pages/customer/profile/subpages/ProfileHobbies';
import ProfileHelp from '@/pages/customer/profile/subpages/ProfileHelp';
import AdminLanguage from '@/pages/admin/languages/AdminLanguage';
import AdminCustomerProfile from '@/pages/admin/customer-profile/AdminCustomerProfile';
import AdminQualityIndicator from '@/pages/admin/report-quality-indicator/AdminQualityIndicator';
import NewAdminLayout from '../components/layout/NewAdminLayout';
import Paywall from '@/pages/customer/paywall/Paywall';
import Workflow from '@/pages/admin/home/components/Workflow';
import Common from '@/pages/admin/home/components/Common';
import Report from '@/pages/admin/home/components/Report';
import AdminMetaReport from '@/pages/admin/report-meta/AdminMetaReport';
import AdminNews from '@/pages/admin/news/News';
import AdminPopulare from '@/pages/admin/populare/AdminPopulare';
import ProfileEdit from '@/pages/customer/profile/subpages/ProfileEdit';
import AdminOpinions from '@/pages/admin/opinions/AdminOpinions';
import ProfileLevel from '@/pages/customer/profile/subpages/ProfileLevel';
import ProfileDailyMinutes from '@/pages/customer/profile/subpages/ProfileDailyMinutes';
import AdminUTMReport from '@/pages/admin/report-utm/AdminUTMReport';
import AdminCallMode from '@/pages/admin/call-mode-config/AdminCallMode';
import CallMode from '@/pages/customer/callMode/CallMode';
import AdminTranslation from '@/pages/admin/translation/Translation';
import AdminWebPushClients from '@/pages/admin/webPushClients/WebPushClients';
import AdminWebPushNotificationSent from '@/pages/admin/webPushNotificationSent/WebPushNotificationSent';
import AdminNotification from '@/pages/admin/notification/Notification';
import AdminPaymentType from '@/pages/admin/paymentType/PaymentType';
import AdminCurrency from '@/pages/admin/currency/Currency';
import AdminCountry from '@/pages/admin/country/Country';
import AdminPrice from '@/pages/admin/price/Price';
import AdminPriceConfigManagment from '@/pages/admin/price-managment/PriceConfig';
import AdminPromotion from '@/pages/admin/promotion/Promotion';
import ProfilePlan from '@/pages/customer/profile/subpages/ProfilePlan';
import Checkout from '@/pages/checkout/Checkout';
import AdminSpends from '@/pages/admin/spends/Spends';
import MailTemplates from '@/pages/admin/mailTemplates/MailTemplates';
import MailConfig from '@/pages/admin/mailConfig/MailConfig';
import MailReport from '@/pages/admin/mail-report/MailReport';
import AdminChatConfigs from '@/pages/admin/chat-configs/AdminChatConfigs';
import ChatTestReport from '@/pages/admin/chat-test-report/ChatTestReport';
import RefundationReport from '@/pages/admin/redundation-report/RefundationReport';
import AdminSearch from '@/pages/admin/search/AdminSearch';
import ProfileLearningLanguage from '@/pages/customer/profile/subpages/ProfileLearningLanguage';
import DailyWrapper from '@/pages/customer/daily/DailyWrapper';
import RenewReport from '@/pages/admin/renew-report/RenewReport';
import CustomerStatsWrapper from '@/pages/admin/user/components/customerStats/CustomerStatsWrapper';
import FinancialReport from '@/pages/admin/advert-report/FinancialReport';
import PaymentReport from '@/pages/admin/payment-report/PaymentReport';
import QuizReport from '@/pages/admin/quiz-report/QuizReport';

const routes = [
  {
    path: '/restart-password/:id',
    element: <RestartPassword />,
  },
  {
    path: 'admin/mail',
    element: (
      <NewAdminLayout>
        <AdminMail />
      </NewAdminLayout>
    ),
  },
  {
    path: 'admin/home',
    element: (
      <NewAdminLayout>
        <AdminHome />
      </NewAdminLayout>
    ),
  },
  {
    path: 'admin/quality-indicator',
    element: (
      <NewAdminLayout>
        <AdminQualityIndicator />
      </NewAdminLayout>
    ),
  },
  {
    path: 'admin/daily-config',
    element: (
      <NewAdminLayout>
        <AdminDailyConversation />
      </NewAdminLayout>
    ),
  },
  {
    path: 'admin/chat-config',
    element: (
      <NewAdminLayout>
        <AdminChatConfigs />
      </NewAdminLayout>
    ),
  },
  {
    path: 'admin/call-mode-config',
    element: (
      <NewAdminLayout>
        <AdminCallMode />
      </NewAdminLayout>
    ),
  },
  {
    path: 'admin/meta-report',
    element: (
      <NewAdminLayout>
        <AdminMetaReport />
      </NewAdminLayout>
    ),
  },
  {
    path: 'admin/utm-report',
    element: (
      <NewAdminLayout>
        <AdminUTMReport />
      </NewAdminLayout>
    ),
  },
  {
    path: 'admin/ai-persons',
    element: (
      <NewAdminLayout>
        <AdminAiPerson />
      </NewAdminLayout>
    ),
  },
  {
    path: 'admin/language',
    element: (
      <NewAdminLayout>
        <AdminLanguage />
      </NewAdminLayout>
    ),
  },
  {
    path: 'admin/customer-profile',
    element: (
      <NewAdminLayout>
        <AdminCustomerProfile />
      </NewAdminLayout>
    ),
  },
  {
    path: 'admin/flashcard-categories',
    element: (
      <NewAdminLayout>
        <AdminFlashcardCategory />
      </NewAdminLayout>
    ),
  },
  {
    path: 'admin/daily-history',
    element: (
      <NewAdminLayout>
        <AdminDailyHistory />
      </NewAdminLayout>
    ),
  },
  {
    path: 'admin/order',
    element: (
      <NewAdminLayout>
        <AdminOrder />
      </NewAdminLayout>
    ),
  },
  {
    path: 'admin/panel',
    element: (
      <NewAdminLayout>
        <AdminPanel />
      </NewAdminLayout>
    ),
  },
  {
    path: 'success-payment',
    element: <Success />,
  },
  {
    path: 'success-payment/:id',
    element: <Success />,
  },
  {
    path: 'admin/workflow',
    element: (
      <NewAdminLayout>
        <Workflow />
      </NewAdminLayout>
    ),
  },
  {
    path: 'admin/searchplace',
    element: (
      <NewAdminLayout>
        <AdminSearch />
      </NewAdminLayout>
    ),
  },
  {
    path: 'admin/web-push-notification',
    element: (
      <NewAdminLayout>
        <AdminNotification />
      </NewAdminLayout>
    ),
  },
  {
    path: 'admin/web-push-sent',
    element: (
      <NewAdminLayout>
        <AdminWebPushNotificationSent />
      </NewAdminLayout>
    ),
  },
  {
    path: 'admin/promotion',
    element: (
      <NewAdminLayout>
        <AdminPromotion />
      </NewAdminLayout>
    ),
  },
  {
    path: 'admin/common',
    element: (
      <NewAdminLayout>
        <Common />
      </NewAdminLayout>
    ),
  },
  {
    path: 'admin/spends',
    element: (
      <NewAdminLayout>
        <AdminSpends />
      </NewAdminLayout>
    ),
  },
  {
    path: 'admin/translation',
    element: (
      <NewAdminLayout>
        <AdminTranslation />
      </NewAdminLayout>
    ),
  },
  {
    path: 'admin/price-config',
    element: (
      <NewAdminLayout>
        <AdminPriceConfigManagment />
      </NewAdminLayout>
    ),
  },
  {
    path: 'admin/price',
    element: (
      <NewAdminLayout>
        <AdminPrice />
      </NewAdminLayout>
    ),
  },
  {
    path: 'admin/country',
    element: (
      <NewAdminLayout>
        <AdminCountry />
      </NewAdminLayout>
    ),
  },
  {
    path: 'admin/mail-templates',
    element: (
      <NewAdminLayout>
        <MailTemplates />
      </NewAdminLayout>
    ),
  },
  {
    path: 'admin/mail-config',
    element: (
      <NewAdminLayout>
        <MailConfig />
      </NewAdminLayout>
    ),
  },
  {
    path: 'admin/reports',
    element: (
      <NewAdminLayout>
        <Report />
      </NewAdminLayout>
    ),
  },
  {
    path: 'admin/mail-report',
    element: (
      <NewAdminLayout>
        <MailReport />
      </NewAdminLayout>
    ),
  },
  {
    path: 'admin/chat-test-report',
    element: (
      <NewAdminLayout>
        <ChatTestReport />
      </NewAdminLayout>
    ),
  },
  {
    path: 'admin/refundation-report',
    element: (
      <NewAdminLayout>
        <RefundationReport />
      </NewAdminLayout>
    ),
  },
  {
    path: 'admin/financial-report',
    element: (
      <NewAdminLayout>
        <FinancialReport />
      </NewAdminLayout>
    ),
  },
  {
    path: 'admin/renew-report',
    element: (
      <NewAdminLayout>
        <RenewReport />
      </NewAdminLayout>
    ),
  },
  {
    path: 'admin/payment-report',
    element: (
      <NewAdminLayout>
        <PaymentReport />
      </NewAdminLayout>
    ),
  },
  {
    path: 'admin/customer/stats/:id',
    element: (
      <NewAdminLayout>
        <CustomerStatsWrapper />
      </NewAdminLayout>
    ),
  },
  {
    path: 'admin/category',
    element: (
      <NewAdminLayout>
        <AdminCategory />
      </NewAdminLayout>
    ),
  },
  {
    path: 'admin/hands',
    element: (
      <NewAdminLayout>
        <AdminHands />
      </NewAdminLayout>
    ),
  },
  {
    path: 'admin/word-lesson-type',
    element: (
      <NewAdminLayout>
        <AdminWordLessonType />
      </NewAdminLayout>
    ),
  },
  {
    path: 'admin/word-lesson-top-type',
    element: (
      <NewAdminLayout>
        <AdminWordLessonTopType />
      </NewAdminLayout>
    ),
  },
  {
    path: 'admin/web-push-client',
    element: (
      <NewAdminLayout>
        <AdminWebPushClients />
      </NewAdminLayout>
    ),
  },
  {
    path: 'learn/flashcards/:id',
    element: (
      <ProductLayout>
        <NewFlashcardCategory />
      </ProductLayout>
    ),
  },
  {
    path: 'learn/call-mode',
    element: (
      <ProductLayout>
        <CallMode />
      </ProductLayout>
    ),
  },
  {
    path: 'learn/flashcards',
    element: (
      <ProductLayout>
        <NewFlashcardList />
      </ProductLayout>
    ),
  },
  {
    path: 'learn/flashcards/flashcard/:id',
    element: (
      <ProductLayout>
        <NewFlashcard />
      </ProductLayout>
    ),
  },
  {
    path: 'admin/user',
    element: (
      <NewAdminLayout>
        <AdminUser />
      </NewAdminLayout>
    ),
  },
  {
    path: 'admin/lesson',
    element: (
      <NewAdminLayout>
        <AdminLesson />
      </NewAdminLayout>
    ),
  },
  {
    path: 'admin/news',
    element: (
      <NewAdminLayout>
        <AdminNews />
      </NewAdminLayout>
    ),
  },
  {
    path: 'admin/word-lesson-type-config',
    element: (
      <NewAdminLayout>
        <AdminWordLessonConfig />
      </NewAdminLayout>
    ),
  },
  {
    path: 'admin/currency',
    element: (
      <NewAdminLayout>
        <AdminCurrency />
      </NewAdminLayout>
    ),
  },
  {
    path: 'admin/payment-type',
    element: (
      <NewAdminLayout>
        <AdminPaymentType />
      </NewAdminLayout>
    ),
  },
  {
    path: '/',
    element: (
      <ProductLayout>
        <Home />
      </ProductLayout>
    ),
  },
  {
    path: '/learn/chat',
    element: (
      <ProductLayout>
        <DailyWrapper />
      </ProductLayout>
    ),
  },
  {
    path: '/learn/chat/:id',
    element: (
      <ProductLayout>
        <DailyWrapper />
      </ProductLayout>
    ),
  },
  {
    path: '/learn/scenes',
    element: (
      <ProductLayout>
        <Lessons />
      </ProductLayout>
    ),
  },

  {
    path: '/learn/scenes/:id',
    element: (
      <ProductLayout>
        <Lesson />
      </ProductLayout>
    ),
  },
  {
    path: '/profile',
    element: (
      <ProductLayout>
        <Profile />
      </ProductLayout>
    ),
  },
  {
    path: '/profile/edit',
    element: (
      <ProductLayout>
        <ProfileEdit />
      </ProductLayout>
    ),
  },
  {
    path: '/profile/interests',
    element: (
      <ProductLayout>
        <ProfileHobbies />
      </ProductLayout>
    ),
  },
  {
    path: '/profile/your-plan',
    element: (
      <ProductLayout>
        <ProfilePlan />
      </ProductLayout>
    ),
  },
  {
    path: '/profile/help',
    element: (
      <ProductLayout>
        <ProfileHelp />
      </ProductLayout>
    ),
  },
  {
    path: '/profile/learning/daily-minutes',
    element: (
      <ProductLayout>
        <ProfileDailyMinutes />
      </ProductLayout>
    ),
  },
  {
    path: '/profile/learning/learning-language',
    element: (
      <ProductLayout>
        <ProfileLearningLanguage />
      </ProductLayout>
    ),
  },
  {
    path: '/profile/learning/level',
    element: (
      <ProductLayout>
        <ProfileLevel />
      </ProductLayout>
    ),
  },
  {
    path: '/profile/tutor',
    element: (
      <ProductLayout>
        <ProfileTutor />
      </ProductLayout>
    ),
  },
  {
    path: '/profile/learning',
    element: (
      <ProductLayout>
        <ProfileLearning />
      </ProductLayout>
    ),
  },
  {
    path: '/learn/words/:id',
    element: (
      <ProductLayout>
        <WordLessonType />
      </ProductLayout>
    ),
  },
  {
    path: '/learn/words',
    element: (
      <ProductLayout>
        <WordLessonTypeList />
      </ProductLayout>
    ),
  },
  // {
  //   path: '/register',
  //   element: <Register />,
  // },
  {
    path: '/paywall',
    element: <Paywall />,
  },
  // {
  //   path: '/register/:id',
  //   element: <Register />,
  // },
  {
    path: '/login',
    element: <Login />,
  },
  {
    path: '/checkout',
    element: <Checkout />,
  },
  {
    path: '/learn',
    element: (
      <ProductLayout>
        <Learn />
      </ProductLayout>
    ),
  },
  {
    path: '/learn/foto-mode',
    element: (
      <ProductLayout>
        <FotoMode />
      </ProductLayout>
    ),
  },
  {
    path: 'admin/quiz-report',
    element: (
      <NewAdminLayout>
        <QuizReport />
      </NewAdminLayout>
    ),
  },
  {
    path: 'admin/flashcard',
    element: (
      <NewAdminLayout>
        <AdminFlashcard />
      </NewAdminLayout>
    ),
  },
  {
    path: 'admin/opinions',
    element: (
      <NewAdminLayout>
        <AdminOpinions />
      </NewAdminLayout>
    ),
  },
  {
    path: 'admin/image-mode',
    element: (
      <NewAdminLayout>
        <AdminImageMode />
      </NewAdminLayout>
    ),
  },
  {
    path: 'admin/populare',
    element: (
      <NewAdminLayout>
        <AdminPopulare />
      </NewAdminLayout>
    ),
  },
  {
    path: 'admin/ip-stats',
    element: (
      <NewAdminLayout>
        <AdminIpStats />
      </NewAdminLayout>
    ),
  },
  {
    path: 'admin/usage-stats',
    element: (
      <NewAdminLayout>
        <AdminUsage />
      </NewAdminLayout>
    ),
  },
  {
    path: 'admin/image-mode-stats',
    element: (
      <NewAdminLayout>
        <AdminFotoModeStats />
      </NewAdminLayout>
    ),
  },
];

export default routes;
