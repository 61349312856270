import React, { useState } from 'react';
import { styled } from 'styled-components';
import { useMutation } from 'react-query';
import SearchBar from './components/SearchBar';
import Loading from '@/common/components/Loading';
import { findQuizReport } from '@/api/query/report';
import DataTable from '@/common/components/custom/DataTable';
import NewCustomTable from '@/common/components/custom/NewCustomTable';
import {
  PAYU_OPERATOR_COUNTRIES,
  STRIPE_ONE_OFF,
} from '@/pages/checkout/Checkout';

const Wrapper = styled.div`
  padding: 20px 20px 0 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const TableWrapper = styled.div`
  background-color: white;
  border-radius: 15px;
  padding: 10px;
`;

const HEADERS = ['Event', 'Amount', 'Partial CR ', 'Total CR'];

const EVENTS_LIST = [
  'AD_LP2_PAGEVIEW_LP',
  'AD_LP2_PAGEVIEW_Q1',
  'AD_LP2_PAGEVIEW_Q2',
  'AD_LP2_PAGEVIEW_Q3',
  'AD_LP2_PAGEVIEW_Q4',
  'AD_LP2_PAGEVIEW_Q5',
  'AD_LP2_PAGEVIEW_Q6',
  'AD_LP2_PAGEVIEW_Q7',
  'AD_LP2_PAGEVIEW_Q8',
  'AD_LP2_PAGEVIEW_Q9',
  'AD_LP2_PAGEVIEW_Q10',
  'AD_LP2_PAGEVIEW_Q11',
  'AD_LP2_PAGEVIEW_Q12',
  'AD_LP2_PAGEVIEW_Q13',
  'AD_LP2_PAGEVIEW_Q14',
  'AD_LP2_PAGEVIEW_Q15',
  'AD_LP2_PAGEVIEW_Q16',
  'AD_LP2_PAGEVIEW_Q17',
  'AD_LP2_PAGEVIEW_Q18',
  'AD_LP2_PAGEVIEW_Q19',
  'AD_LP2_PAGEVIEW_Q20',
  'AD_LP2_PAGEVIEW_Q21',
  'AD_LP2_PAGEVIEW_Q22',
  'AD_LP2_PAGEVIEW_Q23',
  'AD_LP2_PAGEVIEW_Q24',
  'AD_LP2_PAGEVIEW_Q25',
  'AD_LP2_PAGEVIEW_Q26',
  'AD_LP2_PAGEVIEW_Q27',
  'AD_LP2_PAGEVIEW_Q28',
  'AD_LP2_PAGEVIEW_SP',
];

const QuizReport = () => {
  const [tableData, setTableData] = useState([]);
  const [tableDataDetails, setTableDataDetails] = useState([]);

  const roundToPercent = (data) =>
    (data * 100).toFixed(2)?.replace('.', ',') + ' %';

  const findStatsMutation = useMutation({
    mutationFn: (values) => findQuizReport(values),
    onSuccess: ({ data }) => {
      const checkoutSum =
        (data.AD_LP2_ADDTOCART_1MO || 0) +
        (data.AD_LP2_ADDTOCART_3MO || 0) +
        (data.AD_LP2_ADDTOCART_6MO || 0);

      const paymentPageSum =
        (data.AD_LP2_CHECKOUT_1MO || 0) +
        (data.AD_LP2_CHECKOUT_3MO || 0) +
        (data.AD_LP2_CHECKOUT_6MO || 0);

      const checkoutSumPromo =
        (data.AD_LP2_ADDTOCART_1MO_PROMO || 0) +
        (data.AD_LP2_ADDTOCART_3MO_PROMO || 0) +
        (data.AD_LP2_ADDTOCART_6MO_PROMO || 0);

      const paymentPageSumPromo =
        (data.AD_LP2_CHECKOUT_3MO_PROMO || 0) +
        (data.AD_LP2_CHECKOUT_6MO_PROMO || 0) +
        (data.AD_LP2_CHECKOUT_1MO_PROMO || 0);

      const ONE_OFF = [...PAYU_OPERATOR_COUNTRIES, ...STRIPE_ONE_OFF].includes(
        data.country,
      );

      setTableData(() => [
        ['LP views', data.AD_LP2_PAGEVIEW_LP, '---', '---'],
        [
          'Start quiz',
          data.AD_LP2_PAGEVIEW_Q1,
          roundToPercent(data.AD_LP2_PAGEVIEW_Q1 / data.AD_LP2_PAGEVIEW_LP),
          roundToPercent(data.AD_LP2_PAGEVIEW_Q1 / data.AD_LP2_PAGEVIEW_LP),
        ],
        [
          'Finish quiz',
          data.AD_LP2_PAGEVIEW_Q28,
          roundToPercent(data.AD_LP2_PAGEVIEW_Q28 / data.AD_LP2_PAGEVIEW_Q1),
          roundToPercent(data.AD_LP2_PAGEVIEW_Q28 / data.AD_LP2_PAGEVIEW_LP),
        ],
        [
          'SP views (leads)',
          data.AD_LP2_PAGEVIEW_SP,
          roundToPercent(data.AD_LP2_PAGEVIEW_SP / data.AD_LP2_PAGEVIEW_Q28),
          roundToPercent(data.AD_LP2_PAGEVIEW_SP / data.AD_LP2_PAGEVIEW_LP),
        ],
        [
          'Checkout',
          checkoutSum,
          roundToPercent(checkoutSum / data.AD_LP2_PAGEVIEW_SP),
          roundToPercent(checkoutSum / data.AD_LP2_PAGEVIEW_LP),
        ],
        ONE_OFF
          ? [
              'Payment Page',
              paymentPageSum,
              roundToPercent(paymentPageSum / checkoutSum),
              roundToPercent(paymentPageSum / data.AD_LP2_PAGEVIEW_LP),
            ]
          : [],
        [
          'Purchase (ALL)',
          data.purchase,
          roundToPercent(
            data.purchase / (ONE_OFF ? paymentPageSum : checkoutSum),
          ),
          roundToPercent(data.purchase / data.AD_LP2_PAGEVIEW_LP),
        ],
        ['Promotion view', data.AD_LP2_PAGEVIEW_SP_PROMO, '----', '----'],
        [
          'Checkout Promo',
          checkoutSumPromo,
          roundToPercent(checkoutSumPromo / data.AD_LP2_PAGEVIEW_SP_PROMO),
          roundToPercent(checkoutSumPromo / data.AD_LP2_PAGEVIEW_SP_PROMO),
        ],
        ONE_OFF
          ? [
              'Payment Page Promo',
              paymentPageSumPromo,
              roundToPercent(paymentPageSumPromo / checkoutSumPromo),
              roundToPercent(
                paymentPageSumPromo / data.AD_LP2_PAGEVIEW_SP_PROMO,
              ),
            ]
          : [],
        [
          'Purchase Promo',
          data.promoPurchase,
          roundToPercent(
            data.promoPurchase /
              (ONE_OFF ? paymentPageSumPromo : checkoutSumPromo),
          ),
          roundToPercent(data.promoPurchase / data.AD_LP2_PAGEVIEW_SP_PROMO),
        ],
      ]);

      setTableDataDetails(() =>
        EVENTS_LIST.map((tdd, i) => [
          tdd,
          data[tdd],
          i !== 0
            ? roundToPercent(data[tdd] / data[EVENTS_LIST[i - 1]])
            : '---',
          i !== 0 ? roundToPercent(data[tdd] / data[EVENTS_LIST[0]]) : '---',
        ]),
      );

      return;
    },
  });

  const handleSearch = (payload) => {
    setTableData(() => []);
    setTableDataDetails(() => []);
    findStatsMutation.mutate(payload);
  };
  return (
    <>
      {findStatsMutation.isLoading && <Loading />}
      <Wrapper>
        <TableWrapper>
          <SearchBar handleSearch={handleSearch} />
        </TableWrapper>
        <TableWrapper>
          <NewCustomTable headers={HEADERS} data={tableData} />
        </TableWrapper>

        <TableWrapper>
          <NewCustomTable headers={HEADERS} data={tableDataDetails} />
        </TableWrapper>
      </Wrapper>
    </>
  );
};

export default QuizReport;
